<template>
  <div class="text-center">
    <v-bottom-sheet v-model="add_new_popup" persistent>
      <template v-slot:activator="{ on, attrs }">
        <h4 v-bind="attrs" v-on="on" class="show-all">
          Add New member
        </h4>
      </template>
      <v-sheet v-if="add_new_popup" class="pa-4" ref="form">
        <div class="flex-between pb-8">
          <h3>Add New Member</h3>
        </div>
        <!-- <div class="text-body-2">Name</div> -->
        <v-text-field
          ref="name"
          v-model="name"
          :rules="[rules.required]"
          label="Name"
          dense
          required
          outlined
          class="mb-3"
        ></v-text-field>
        <v-text-field
          ref="email"
          v-model="email"
          :rules="[rules.email]"
          label="Email"
          dense
          outlined
          class="mb-3"
        ></v-text-field>
        <!-- <div class="text-body-2">Email</div>
          <input
            v-model="email"
            type="email"
            placeholder="venkatarayaacharya@gmail.com"
            class="kvk-input width-100"
          /> -->
        <!-- <div class="mb-4">
          <div class="text-body-2">Date of birth</div>
          <input
            v-model="name"
            type="text"
            placeholder="venkatarayaacharya@gmail.com"
            class="kvk-input width-100"
          />
        </div> -->
        <!-- <div class="text-body-2">Date of Birth</div> -->
        <v-dialog
          ref="birth_modal"
          v-model="birth_modal"
          :return-value.sync="birth_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="date_of_birth"
              v-model="date_of_birth"
              label="Date of Birth"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              class="mb-3"
            ></v-text-field>
            <!-- :rules="[rules.required]" -->
            <!-- required -->
            <!-- single-line -->
          </template>
          <v-date-picker
            v-model="date_of_birth"
            :active-picker.sync="birthPicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            @change="birth_modal = false"
            min="1800-01-01"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="birth_modal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.birth_modal.save(date_of_birth)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-switch v-model="living" inset label="Living" class="ma-0"></v-switch>
        <div v-if="!living">
          <v-dialog
            ref="death_modal"
            v-model="death_modal"
            :return-value.sync="death_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                ref="date_of_death"
                v-model="date_of_death"
                label="Date of Death"
                readonly
                v-bind="attrs"
                v-on="on"
                single-line
                class="mb-3"
                outlined
                dense
              ></v-text-field>
              <!-- :rules="[rules.required]"
                required -->
            </template>
            <v-date-picker
              v-model="date_of_death"
              :active-picker.sync="deathPicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              :min="date_of_birth"
              @change="death_modal = false"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="death_modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.death_modal.save(date_of_death)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <v-radio-group v-model="gender" row class="mb-3">
          <template v-slot:label>
            <span class="mr-2">About member:</span>
          </template>
          <v-radio value="male">
            <template v-slot:label>
              <div>
                Male
              </div>
            </template>
          </v-radio>
          <v-radio value="female">
            <template v-slot:label>
              <div>
                Female
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <div class="flex">
          <div
            @click="add_new_popup = false"
            class="kvk-button is-cancel width-50 mr-2"
          >
            Cancel
          </div>
          <div @click="addMember" class="kvk-button width-50 ml-2">
            Add Member
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {
  doc,
  memberCollection,
  serverTimestamp,
  addDoc,
  updateDoc,
} from "@/firebaseconfig.js";
export default {
  data() {
    return {
      add_new_popup: false,
      name: null,
      email: null,
      date_of_birth: null,
      date_of_death: null,
      error: null,
      birthPicker: null,
      deathPicker: null,
      birth_date: null,
      death_date: null,
      birth_modal: false,
      death_modal: false,
      menu: false,
      living: true,
      formHasErrors: false,
      gender: "male",
      rules: {
        required: (value) => !!value || "Required.",
        // counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  computed: {
    form() {
      return {
        name: this.name,
        email: this.email,
        date_of_birth: this.date_of_birth,
        date_of_death: this.living ? false : this.date_of_death,
      };
    },
  },
  watch: {
    birth_modal(val) {
      val && setTimeout(() => (this.birthPicker = "YEAR"));
    },
    death_modal(val) {
      val && setTimeout(() => (this.deathPicker = "YEAR"));
    },
  },
  methods: {
    async addMember() {
      // ||
      //   this.email !== null ||
      //   this.date_of_birth !== null ||
      //   (this.living == false && this.date_of_death !== null)
      if (this.name !== null || this.name !== "") {
        this.is_loading = true;
        const docRef = await addDoc(memberCollection, {
          added_time: serverTimestamp(),
          name: this.name,
          email: this.email,
          date_of_birth: this.date_of_birth,
          date_of_death: this.date_of_death,
          living: this.living,
          gender: this.gender,
        });

        await updateDoc(doc(memberCollection, docRef.id), {
          id: docRef.id,
          config: { theme: "light", currency: "INR" },
        });

        this.add_new_popup = false;
        this.$emit("added");
        this.name = null;
        this.email = null;
        this.date_of_birth = null;
        this.date_of_death = null;
        this.living = true;
        this.formHasErrors = false;
        this.is_loading = false;
      } else {
        console.log("has error");
        this.formHasErrors = false;
        Object.keys(this.form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true;

          this.$refs[f].validate(true);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
