var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"show-all"},'h4',attrs,false),on),[_vm._v(" Add New member ")])]}}]),model:{value:(_vm.add_new_popup),callback:function ($$v) {_vm.add_new_popup=$$v},expression:"add_new_popup"}},[(_vm.add_new_popup)?_c('v-sheet',{ref:"form",staticClass:"pa-4"},[_c('div',{staticClass:"flex-between pb-8"},[_c('h3',[_vm._v("Add New Member")])]),_c('v-text-field',{ref:"name",staticClass:"mb-3",attrs:{"rules":[_vm.rules.required],"label":"Name","dense":"","required":"","outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{ref:"email",staticClass:"mb-3",attrs:{"rules":[_vm.rules.email],"label":"Email","dense":"","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-dialog',{ref:"birth_modal",attrs:{"return-value":_vm.birth_date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.birth_date=$event},"update:return-value":function($event){_vm.birth_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"date_of_birth",staticClass:"mb-3",attrs:{"label":"Date of Birth","readonly":"","dense":"","outlined":""},model:{value:(_vm.date_of_birth),callback:function ($$v) {_vm.date_of_birth=$$v},expression:"date_of_birth"}},'v-text-field',attrs,false),on))]}}],null,false,1088220818),model:{value:(_vm.birth_modal),callback:function ($$v) {_vm.birth_modal=$$v},expression:"birth_modal"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.birthPicker,"max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),"min":"1800-01-01"},on:{"update:activePicker":function($event){_vm.birthPicker=$event},"update:active-picker":function($event){_vm.birthPicker=$event},"change":function($event){_vm.birth_modal = false}},model:{value:(_vm.date_of_birth),callback:function ($$v) {_vm.date_of_birth=$$v},expression:"date_of_birth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.birth_modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.birth_modal.save(_vm.date_of_birth)}}},[_vm._v(" OK ")])],1)],1),_c('v-switch',{staticClass:"ma-0",attrs:{"inset":"","label":"Living"},model:{value:(_vm.living),callback:function ($$v) {_vm.living=$$v},expression:"living"}}),(!_vm.living)?_c('div',[_c('v-dialog',{ref:"death_modal",attrs:{"return-value":_vm.death_date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.death_date=$event},"update:return-value":function($event){_vm.death_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"date_of_death",staticClass:"mb-3",attrs:{"label":"Date of Death","readonly":"","single-line":"","outlined":"","dense":""},model:{value:(_vm.date_of_death),callback:function ($$v) {_vm.date_of_death=$$v},expression:"date_of_death"}},'v-text-field',attrs,false),on))]}}],null,false,981429060),model:{value:(_vm.death_modal),callback:function ($$v) {_vm.death_modal=$$v},expression:"death_modal"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.deathPicker,"max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),"min":_vm.date_of_birth},on:{"update:activePicker":function($event){_vm.deathPicker=$event},"update:active-picker":function($event){_vm.deathPicker=$event},"change":function($event){_vm.death_modal = false}},model:{value:(_vm.date_of_death),callback:function ($$v) {_vm.date_of_death=$$v},expression:"date_of_death"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.death_modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.death_modal.save(_vm.date_of_death)}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e(),_c('v-radio-group',{staticClass:"mb-3",attrs:{"row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mr-2"},[_vm._v("About member:")])]},proxy:true}],null,false,2697360065),model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('v-radio',{attrs:{"value":"male"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Male ")])]},proxy:true}],null,false,464728338)}),_c('v-radio',{attrs:{"value":"female"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Female ")])]},proxy:true}],null,false,4019721009)})],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"kvk-button is-cancel width-50 mr-2",on:{"click":function($event){_vm.add_new_popup = false}}},[_vm._v(" Cancel ")]),_c('div',{staticClass:"kvk-button width-50 ml-2",on:{"click":_vm.addMember}},[_vm._v(" Add Member ")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }