<template>
  <div class="home">
    <!-- Home -->
    <home-events class="mb-8" />
    <home-transactions class="mb-8" />
    <home-members />
  </div>
</template>
<script>
import HomeEvents from "@/components/home/components/home-events.vue";
import HomeTransactions from "@/components/home/components/home-transaction.vue";
import HomeMembers from "@/components/home/components/home-members.vue";
export default {
  components: {
    "home-events": HomeEvents,
    "home-transactions": HomeTransactions,
    "home-members": HomeMembers,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding: 1rem;
}
</style>
