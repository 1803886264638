<template>
  <div
    class="transactions"
    v-touch="{
      left: () => {
        tab_item = 'expense';
        tab = 'expense';
      },
      right: () => {
        tab_item = 'income';
        tab = 'income';
      },
    }"
  >
    <div class="flex-between mb-3">
      <h2 class="">Transactions</h2>
      <div class="show-all">Show All</div>
    </div>
    <v-tabs grow v-model="tab">
      <v-tab @click="tab_item = 'income'" href="#income">Income</v-tab>
      <v-tab @click="tab_item = 'expense'" href="#expense">Expense</v-tab>
    </v-tabs>
    <!-- <v-tabs-items v-model="tab">
      <v-tab-item value="income">
        <div
          v-for="income in incomes"
          :key="income.id"
          class="transactions__card mb-10"
        >
          <div class="flex-between">
            <div class="body-2">{{ income.date }}</div>
            <div class="caption">{{ income.place }}</div>
          </div>
          <div class="transactions__card--title">{{ income.title }}</div>
        </div>
      </v-tab-item>
      <v-tab-item value="expense">
        <div
          v-for="expense in expenses"
          :key="expense.id"
          class="transactions__card"
        >
          <div class="flex-between">
            <div class="body-2">{{ expense.date }}</div>
            <div class="caption">{{ expense.place }}</div>
          </div>
          <div class="transactions__card--title">{{ expense.title }}</div>
        </div>
      </v-tab-item>
    </v-tabs-items> -->
    <div v-if="tab_item == 'income'">
      <income-trans
        v-for="income in incomes"
        :key="income.id"
        :income="income"
      />
    </div>
    <div v-if="tab_item == 'expense'">
      <expense-trans
        v-for="expense in expenses"
        :key="expense.id"
        :expense="expense"
      />
    </div>
  </div>
</template>

<script>
import IncomeTrans from "@/components/home/components/transaction/income.vue";
import ExpenseTrans from "@/components/home/components/transaction/expense.vue";
export default {
  components: {
    IncomeTrans,
    ExpenseTrans,
  },
  data() {
    return {
      tab: null,
      tab_item: "income",
      incomes: [
        {
          id: 1,
          title:
            "fsdai fsda fsdafsdafsdaifhsdafo  sdaifi fsdafhsd foasd hfohsdaofa",
          date: "17-04-2021",
          amount: 25,
        },
        { id: 2, title: "ABC", date: "17-04-2021", amount: 25 },
        { id: 3, title: "ABC", date: "17-04-2021", amount: 25 },
        { id: 4, title: "ABC", date: "17-04-2021", amount: 25 },
        { id: 5, title: "ABC", date: "17-04-2021", amount: 25 },
      ],
      expenses: [
        { id: 1, title: "ABC", date: "17-04-2021", amount: 25 },
        { id: 2, title: "ABC", date: "17-04-2021", amount: 25 },
        { id: 3, title: "ABC", date: "17-04-2021", amount: 25 },
        { id: 4, title: "ABC", date: "17-04-2021", amount: 25 },
        { id: 5, title: "ABC", date: "17-04-2021", amount: 25 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
