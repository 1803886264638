var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events"},[_c('div',{staticClass:"flex-between mb-3"},[_c('h2',{},[_vm._v("Events")]),_c('div',{staticClass:"show-all",on:{"click":function($event){return _vm.$router.push({ name: 'Events' })}}},[_vm._v(" Show All ")])]),(_vm.events && _vm.events.length)?_c('div',_vm._l((_vm.events),function(event){return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
        left: function () {
          _vm.show_delete_icon = true;
          _vm.delete_user = event.id;
        },
        right: function () {
          _vm.show_delete_icon = false;
          _vm.delete_user = null;
        },
      }),expression:"{\n        left: () => {\n          show_delete_icon = true;\n          delete_user = event.id;\n        },\n        right: () => {\n          show_delete_icon = false;\n          delete_user = null;\n        },\n      }"}],key:event.id,staticClass:"events__card"},[_c('div',{staticClass:"flex-between"},[_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm._f("prettyDateFormat")(event.date_of_event,"CUSTOM"))+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(event.place))])]),_c('div',{staticClass:"flex-between"},[_c('div',{staticClass:"events__card--title"},[_vm._v(_vm._s(event.title))]),(_vm.show_delete_icon && _vm.delete_user == event.id)?_c('div',[_c('icon',{staticClass:"is-24x24",attrs:{"name":"x-circle","file":"feather"},nativeOn:{"click":function($event){return _vm.deleteEvent(event.id)}}})],1):_vm._e()])])}),0):_c('div',{staticClass:"flex-center"},[_vm._v(" No events ")])])}
var staticRenderFns = []

export { render, staticRenderFns }