var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"members"},[_c('div',{staticClass:"flex-between mb-3"},[_c('h2',[_vm._v("All Members")]),_c('add-member',{on:{"added":_vm.getDate}})],1),_c('div',{staticClass:"members__list"},_vm._l((_vm.members),function(member){return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
        left: function () {
          _vm.show_delete_icon = true;
          _vm.delete_user = member.id;
        },
        right: function () {
          _vm.show_delete_icon = false;
          _vm.delete_user = null;
        },
      }),expression:"{\n        left: () => {\n          show_delete_icon = true;\n          delete_user = member.id;\n        },\n        right: () => {\n          show_delete_icon = false;\n          delete_user = null;\n        },\n      }"}],key:member.id,staticClass:"members__list--card"},[_c('div',{staticClass:"flex align-center"},[_c('v-avatar',{staticClass:"mr-2 capitalize",attrs:{"color":_vm.stringColor(member.name.charAt(0)),"size":"42"}},[_c('span',{staticClass:"members__list--card__avatar-text",class:[
              _vm.isColorDark(_vm.stringColor(member.name.charAt(0)))
                ? 'primary-text'
                : 'white--text' ]},[_vm._v(_vm._s(member.name.charAt(0)))])]),_c('div',[_c('div',{staticClass:"members__list--card__title"},[_vm._v(_vm._s(member.name))]),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(member.date_of_birth)+" "),(member.date_of_death)?_c('span',[_vm._v(" - "+_vm._s(member.date_of_death))]):_vm._e()])])],1),(_vm.show_delete_icon && _vm.delete_user == member.id)?_c('div',[_c('icon',{staticClass:"is-24x24",attrs:{"name":"x-circle","file":"feather"},nativeOn:{"click":function($event){return _vm.deleteMember(member.id)}}})],1):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }