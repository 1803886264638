var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
    left: function () {
      _vm.tab_item = 'expense';
      _vm.tab = 'expense';
    },
    right: function () {
      _vm.tab_item = 'income';
      _vm.tab = 'income';
    },
  }),expression:"{\n    left: () => {\n      tab_item = 'expense';\n      tab = 'expense';\n    },\n    right: () => {\n      tab_item = 'income';\n      tab = 'income';\n    },\n  }"}],staticClass:"transactions"},[_vm._m(0),_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#income"},on:{"click":function($event){_vm.tab_item = 'income'}}},[_vm._v("Income")]),_c('v-tab',{attrs:{"href":"#expense"},on:{"click":function($event){_vm.tab_item = 'expense'}}},[_vm._v("Expense")])],1),(_vm.tab_item == 'income')?_c('div',_vm._l((_vm.incomes),function(income){return _c('income-trans',{key:income.id,attrs:{"income":income}})}),1):_vm._e(),(_vm.tab_item == 'expense')?_c('div',_vm._l((_vm.expenses),function(expense){return _c('expense-trans',{key:expense.id,attrs:{"expense":expense}})}),1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-between mb-3"},[_c('h2',{},[_vm._v("Transactions")]),_c('div',{staticClass:"show-all"},[_vm._v("Show All")])])}]

export { render, staticRenderFns }