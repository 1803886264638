<template>
  <div class="members">
    <div class="flex-between mb-3">
      <h2>All Members</h2>
      <add-member @added="getDate" />
    </div>
    <div class="members__list">
      <!-- <img :src="img" alt="" class="is-100x100" /> -->
      <div
        v-for="member in members"
        :key="member.id"
        class="members__list--card"
        v-touch="{
          left: () => {
            show_delete_icon = true;
            delete_user = member.id;
          },
          right: () => {
            show_delete_icon = false;
            delete_user = null;
          },
        }"
      >
        <!-- left: () => deleteMember(member.id), -->
        <!-- left: () => swipe('Left'),
        right: () => swipe('Right'),
          up: () => swipe('Up'),
          down: () => swipe('Down'), -->
        <div class="flex align-center">
          <v-avatar
            :color="stringColor(member.name.charAt(0))"
            size="42"
            class="mr-2 capitalize"
          >
            <span
              class="members__list--card__avatar-text"
              :class="[
                isColorDark(stringColor(member.name.charAt(0)))
                  ? 'primary-text'
                  : 'white--text',
              ]"
              >{{ member.name.charAt(0) }}</span
            >
          </v-avatar>
          <div>
            <div class="members__list--card__title">{{ member.name }}</div>
            <div class="body-2">
              {{ member.date_of_birth }}
              <span v-if="member.date_of_death">
                - {{ member.date_of_death }}</span
              >
            </div>
          </div>
        </div>
        <div v-if="show_delete_icon && delete_user == member.id">
          <icon
            @click.native="deleteMember(member.id)"
            name="x-circle"
            file="feather"
            class="is-24x24"
          ></icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const stc = require("string-to-color");
import {
  memberCollection,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  doc,
  db,
  getStorage,
  ref,
  getDownloadURL,
} from "@/firebaseconfig.js";
import AddMember from "@/components/home/components/members/add-members.vue";
export default {
  components: {
    "add-member": AddMember,
  },
  data() {
    return {
      members: [],
      show_delete_icon: false,
      delete_user: null,
      img: null,
    };
  },
  mounted() {
    this.getDate();
    const storage = getStorage();
    // this.img =
    getDownloadURL(ref(storage, "profile/PB.jpg")).then((url) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = (event) => {
        const blob = xhr.response;
      };
      xhr.open("GET", url);
      xhr.send();
      this.img = url;
    });
    // console.log(this.img);
  },
  methods: {
    stringColor(text) {
      return stc(text);
    },
    isColorDark(color) {
      const hex = color.replace("#", "");
      const c_r = parseInt(hex.substr(0, 2), 16);
      const c_g = parseInt(hex.substr(2, 2), 16);
      const c_b = parseInt(hex.substr(4, 2), 16);
      const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
      return brightness > 155;
    },
    // swipe() {
    //   this.show_delete_icon = true;
    // },
    async deleteMember(id) {
      // console.log("id", id);
      this.is_loading = true;
      await deleteDoc(doc(db, "members_list", id))
        .then(() => {
          console.log("Deleted Successfully");
          this.delete_user = null;
          this.getDate();
          this.is_loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDate() {
      const q = query(memberCollection, orderBy("name", "asc"));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length) {
        this.members = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        all_members = this.members;
      } else {
        console.log("No data");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.members {
  margin-bottom: 3rem;
  &__list {
    max-height: 400px;
    overflow-x: auto;
    &--card {
      background-color: #fff;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__avatar-text {
        font-size: 20px;
        font-weight: 500;
      }
      &__title {
        margin-top: 0.25rem;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}
</style>
