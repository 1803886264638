<template>
  <div class="income__card">
    <div>
      <div class="body-2 mb-2">{{ income.transaction_date }}</div>
      <div class="income__card--title">{{ income.title }}</div>
    </div>
    <div class="income__card--amount">{{ income.amount | currency }}</div>
  </div>
</template>

<script>
export default {
  props: {
    income: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.income {
  margin-bottom: 2rem;
  &__card {
    background-color: #fff;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--title {
      margin-top: 0.25rem;
      font-size: 1rem;
      font-weight: 500;
      margin-right: 1.25rem;
    }
    &--amount {
      color: $danger;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
}
</style>
