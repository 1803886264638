<template>
  <div class="events">
    <div class="flex-between mb-3">
      <h2 class="">Events</h2>
      <!-- <add-event @added="getData" /> -->
      <div @click="$router.push({ name: 'Events' })" class="show-all">
        Show All
      </div>
    </div>

    <div v-if="events && events.length">
      <div
        v-for="event in events"
        :key="event.id"
        class="events__card"
        v-touch="{
          left: () => {
            show_delete_icon = true;
            delete_user = event.id;
          },
          right: () => {
            show_delete_icon = false;
            delete_user = null;
          },
        }"
      >
        <div class="flex-between">
          <div class="body-2">
            {{ event.date_of_event | prettyDateFormat("CUSTOM") }}
          </div>
          <div class="caption">{{ event.place }}</div>
        </div>
        <div class="flex-between">
          <div class="events__card--title">{{ event.title }}</div>
          <div v-if="show_delete_icon && delete_user == event.id">
            <icon
              @click.native="deleteEvent(event.id)"
              name="x-circle"
              file="feather"
              class="is-24x24"
            ></icon>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex-center">
      No events
    </div>
  </div>
</template>

<script>
// import { DateTime } from "luxon";
// const { DateTime } = require("luxon");
var DateTime = luxon.DateTime;
import AddEvent from "@/components/events/components/add-events.vue";
import {
  eventsCollection,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  doc,
  db,
  getStorage,
  ref,
  getDownloadURL,
  limit,
} from "@/firebaseconfig.js";
export default {
  components: {
    "add-event": AddEvent,
  },
  data() {
    return {
      events: [],
      show_delete_icon: false,
      delete_user: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const q = query(eventsCollection, orderBy("eventDate", "asc"), limit(5));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length) {
        this.events = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } else {
        console.log("No data");
      }
      // console.log(DateTime.);
    },
    async deleteEvent(id) {
      // console.log("id", id);
      this.is_loading = true;
      await deleteDoc(doc(db, "events", id))
        .then(() => {
          console.log("Deleted Successfully");
          this.delete_user = null;
          this.getData();
          this.is_loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.events {
  &__card {
    background-color: #fff;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    box-shadow: 1px 3px 0 rgba(0, 0, 0, 9%);
    &--title {
      margin-top: 0.25rem;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}
</style>
